import React from 'react';
import "./CommonBanner.css"

export default function CommonBanner({className, bannerText}) {
  return (
    <div className={`CommonBannerContainer`}>
        <div className='container'>
            <h1 className='mb-0 text-center'>{bannerText}</h1>
        </div>
    </div>
  )
}
