import React from "react";
import image1 from "../../assets/images/livasa-about/aboutImg1.jpg";
import image2 from "../../assets/images/Services/white-label3.jpg";
import image3 from "../../assets/images/livasa-about/our-products.jpg";
import "./AboutSection1.css";
import AboutSectionSubsection1 from "./AboutSectionSubsection1";
export default function AboutSection1() {
  const aboutContent = [
    {
      image: image1,
      title: "Honey from our own farms",
      bodycopy:
        "Livasa is a premier honey company with a robust infrastructure of over 3000 colonies. We take pride in offering a diverse range of honey products and services, including white labeling options for businesses seeking to showcase our exceptional honey under their own brand. In addition to our own colonies, we also manage and support partner honey farms, working collaboratively to ensure the well-being of bee populations and uphold sustainable beekeeping practices. At Livasa, we are driven by our passion for delivering high-quality honey while fostering a harmonious relationship between nature, bees, and our esteemed partners. ",
    },
    {
      rowreverse: "true",
      image: image2,
      title: "Our Services",
      bodycopy:
        "Livasa is dedicated to offering top-quality agricultural products, with a strong focus on honey. We provide following services for our partners:",
    bodycopy2:"true"
      },
    {
      image: image3,
      title: "Our Products",
      bodycopy:
        "Livasa offers a wide range of honey and honey-based products that cater to diverse preferences and needs. Our collection includes a variety of exquisite honey types, carefully harvested from our thriving colonies, each with its own distinct flavor profile and nutritional benefits. In addition to pure honey, we also provide innovative honey-infused products, such as honey shampoo and soap. These products harness the natural properties of honey to nourish and revitalize your hair and skin, offering a luxurious and indulgent experience. At Livasa, we take pride in delivering exceptional honey and honey products that promote overall well-being and enhance your daily self-care rituals.",
    },
  ];
  return (
    <>
      {aboutContent.map((e) => {
        return (
          <AboutSectionSubsection1
            src={e.image}
            bodycopy={e.bodycopy}
            title={e.title}
            rowreverse={e.rowreverse}
            bodycopy2={e.bodycopy2}
          />
        );
      })}
    </>
  );
}
