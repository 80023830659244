import React from "react";
import { Row, Col } from "react-bootstrap";

export default function AboutSectionSubsection1({
  src,
  title,
  bodycopy,
  rowreverse,
  id,
  bodycopy2,
}) {
  return (
    <Row
      id={id}
      className={`${
        rowreverse
          ? "rowreverse align-items-center justify-content-between about-container rowreversemob"
          : "rowreversemob align-items-center justify-content-between about-container"
      } `}
    >
      <Col md={7}>
        <div className="mt-md-0 mt-3">
          <h3 className="title mb-3">{title}</h3>
          <p className="mb-2">{bodycopy} </p>

          {bodycopy2 && (
            <>
              <p className="mb-1">
                {" "}
                <b>White Labelleing: </b> Livasa offers a top-notch white labeling service, allowing businesses to personalize our premium honey products under their own brand. Trust us to deliver exceptional quality and customized packaging that elevates your brand.
              </p>
              <p className="mb-0">
                {" "}
                <b>Managed Honey Colonies: </b> Livasa excels in managing partner honey colonies, offering comprehensive support to ensure healthy and productive bee populations, fostering successful honey farms through expert colony management.
              </p>
            </>
          )}
        </div>
      </Col>
      <Col md={4}>
        <img src={src} className="w-100" alt="about-images" />
      </Col>
    </Row>
  );
}
