import React, { useState, useEffect, useContext  } from "react";
// import { useContext } from "react";
import "./Products.css";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { AiOutlineLike } from "react-icons/ai";
import MainService from "../../common/MainService";

export default function Products(props) {
  const {
    likes,
    src,
    produtName,
    url,
    origin,
    productPrice,
    discount,
    count,
    qr,
    model_id,
    certificate,
    likecount,
    product,
    buylink
  } = props;
  // const dispatch = useDispatch();

  // const counter = useSelector(selectCount);
  // console.log(counter,"uuuuu")
  const[checkLike, setLike] = useState(likecount)
  // const { handleClick } = useContext(TestoContext);
  const handleClick = (product) => {
    // console.log("oooooooooooooooooo",product)
    MainService.productLikeCount(product)
    .then((res)=>{
      console.log("ttttttttttttttttttttttttt",res);
      setLike(checkLike+1)
      // dispatch(increment())
    })
    .catch((err)=>{
      console.log("rerere",err);
    })
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open(certificate, "_blank");
  };
  return (
    
    <div className="product-main-container mb-md-5 mb-4 ">
      <Row>
        <Col md={6}>
          <div className="product-container">
            <img alt = "Img"  src={src} />


            {/* <div>
              <img src={image2}/>
              <img/>
            </div> */}
          </div>
        </Col>

        <Col md={6}>
        <h2>{model_id}</h2>

          <h4 className="d-inline-block mb-2">{produtName}</h4>
          <p>
            Product Details:{" "}
            <a target="_blank" href={url}>
             Info Link
            </a>
          </p>
          <p>
            Country of Origin: <span>{origin}</span>
          </p>
          <p>
            MRP: <span className="priceSpan">{productPrice}</span>
          </p>
          <p>
            Discounted Price: <span>{discount}</span>
          </p>
          <p>
            Certificates: <span><a href={certificate}  target="_blank"download >Certificates</a></span>
          </p>
          <p>
            Count: <span>{count}</span>
          </p>

          <p className="likeSection">
            <span>{checkLike} </span>
            <AiOutlineLike
              onClick={ () => {handleClick(product)}}
            />
          </p>
          <img className="d-block mb-2" width="140" height="140" src={qr} alt = "Img"/>
         <a  className="buy-now d-inline-block" target="_blank" href={buylink}>Buy Now</a>
        </Col>
      </Row>
    </div>
  );
}
