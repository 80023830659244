export const getSortedData = (prodArr, sortBy) => {
  switch (sortBy) {
    case "FEATURED":
      return prodArr.filter((e) => {
        return e.featured === true;
      });

    case "BESTSELLER":
      return prodArr.filter((e) => {
        return e.ratings >= 4;
      });
    case "A_Z":
      return prodArr.sort((a, b) => a.productfriendlyname.localeCompare(b.productfriendlyname));

      case "Z_A":
      return prodArr.sort((a, b) => b.productfriendlyname.localeCompare(a.productfriendlyname));

    case "PRICE_LOW_TO_HIGH":
      return prodArr.sort((a, b) => a.productcost - b.productcost);

    case "PRICE_HIGH_TO_LOW":
      return prodArr.sort((a, b) => b.productcost - a.productcost);

    case "RATING_HIGH_TO_LOW":
      return prodArr.sort((a, b) => b.rating - a.rating);

      case "DATE_OLD_TO_NEW":
      return prodArr.sort((a,b) =>
        new Date(a.timestamp) -new Date(b.timestamp)
      );

      case "DATE_NEW_TO_OLD":
      return prodArr.sort((a,b) =>
        new Date(b.timestamp) -new Date(a.timestamp)
      );

    case "none":
      return prodArr;

    default:
      console.log("Error in data");
      return prodArr;
  }
};
