import React from 'react'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import ServiceSection1 from '../../components/ServiceSection1/ServiceSection1'

export default function Services() {
  return (
    <>
    <CommonBanner bannerText="Our Services"/>
    <div className='honeycomb'></div>
    <ServiceSection1/>
    </>
  )
}
