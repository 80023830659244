import React from 'react'

export default function USPSection2Cards({src,cardBodycopy,cardtitle}) {
  return (
    <div className='USPcards mb-5' >
        <img src={src} className='mb-3'alt="usp"/>
        <h2 className='mb-2'>{cardtitle}</h2>
        <p className='mb-0'>{cardBodycopy}</p>
    </div>
  )
}
