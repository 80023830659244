import React from "react";
import "./About.css";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import AboutSection1 from "../../components/AboutSection1/AboutSection1";

export default function About() {
  return (
<>
<CommonBanner bannerText="About Livasa"/>
<div className="honeycomb"></div>

<div className="container">
<AboutSection1/>
</div>
</>
  );
}
