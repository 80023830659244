import React from 'react'
import './USP.css'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import USPSection2 from '../../components/USPSection2/USPSection2'

export default function USP() {
  return (
    <>
        <CommonBanner bannerText="Our Unique Selling Point"/>
        <USPSection2/>
        
    </>
  )
}
