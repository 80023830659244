if (`${process.env.REACT_APP_DEVELOPMENT_ENV}` === "staging") {
  window.livasaServiceUrl = "https://iamstaging.gbrservice.com/";
  window.livasa_ContentUrl = "https://proxystaging.gbrservice.com/";
  // window.livasa_ContentUrl = "https://content.gbrservice.com/";

  window.networkid = process.env.REACT_APP_CONSUMER_ADMIN_STAGING;
} else if (`${process.env.REACT_APP_DEVELOPMENT_ENV}` === "production") {
  window.livasaServiceUrl = "https://iam.gbrservice.com/";
  window.livasa_ContentUrl = "https://content.gbrservice.com/";
  window.networkid = process.env.REACT_APP_CONSUMER_ADMIN_PRODUCTION;
}

let subcatogory = "Livasa-Natural-Honey";
let user = "limrahoney@gmail.com";
window.livasaConfig = {
  subcatogory: subcatogory,
  user: user,
  networkid: window.networkid,
  token: window.livasaServiceUrl + "AuthBackEndGw/token/",
  getCategories: window.livasa_ContentUrl + "ContentGw/getCategories/",
  modelBasedOnCategory:
    window.livasa_ContentUrl + "AgriProductProxy/getproductlist/",
  productLikeCount:
    window.livasa_ContentUrl + "AgriProductProxy/updateLikeCount/",
  getSubCategories:
    window.livasa_ContentUrl + "ContentGw/getAllContentSubCategories/",
};
