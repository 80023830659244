import React, { useEffect, useState } from "react";
import "./Home.css";
import Banner from "../../components/banner/Banner";
import Title from "../../components/titles/Title";
import Benefits from "../../assets/images/Benefits.jpg";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { NavHashLink } from "react-router-hash-link";
import soap from "../../assets/images/soap-honey-comb-arrangement-high-angle.jpg";
import honey from "../../assets/images/honey.jpg";
import shilajit from "../../assets/images/279.jpg";
import Products from "../../components/products/Products";
import GBRFarminglogo from "../../assets/images/Logo.png";
import MainService from "../../common/MainService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Circles } from "react-loader-spinner";
import CollectionContainer from "../../components/collectionContainer/CollectionContainer";

export default function Home() {


  const accessToken = () => {
    MainService.getAccess()
      .then((res) => {
      })
      .then(() => {
        getCategoriesHandler();
      })
      .catch((err) => {
        console.log("errrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", err);
      });
  };

  useEffect(() => {
    accessToken();
  }, []);

  const [getCatId, setCatId] = useState("");
  const [getHoneyProducts, setHoneyProducts] = useState([]);
  const [loding, setLoading] = useState(true);

  const [subCategory, setSubCategory] = useState([]);



  const getCategoriesHandler = () => {
    MainService.getCategoriesBasedOnParentId()
      .then((res) => {
        const catFilter = res.filter((resdata, index) => {
          return resdata.categoryname === "PROCESSED-PRODUCTS";
        });
        setCatId(catFilter[0].categoryid);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  localStorage.setItem("catID", getCatId);
  useEffect(() => {
    if (!getCatId || getCatId === "") return;
    getProductsHandler();
    getAllSubCategory();
  }, [getCatId]);

  const getProductsHandler = () => {
    MainService.productModelBasedonSubcategory(
      getCatId,
      "Livasa-Natural-Honey",
      "limrahoney@gmail.com"
    )
      .then((res) => {
        setHoneyProducts(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("rrrrrrrrrrrrsssssssss", err);
      });
  };
  var settings = {
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 1,

    responsive: [
      {
        initialSlide: 1,
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          prevArrow: false,
          nextArrow: false,
          arrows: false,
        },
      },
    ],
  };

  const getAllSubCategory = () => {
    MainService.getSubCategories("limrahoney@gmail.com", "ei8qoPsMsz")
      .then((res) => {
        setSubCategory(res.contentsubcategories);
        setLoading(false);

      })
      .catch((e) => {
        console.log("555555555555555e", e);
      });
  };

  const subcategoryImageMapping = {
    "Livasa-Natural-Honey": honey,
    "Livasa-Soap": soap,
    "Livasa-shampoo": shilajit,
  };
  const defaultImageUrl = honey;
  return (
    <div>
      <Banner />
      <div className="section-container">
        <Title classname="text-center mb-4 mb-lg-5" heading="Collections" />
        <div className="container">
          <Row className="justify-content-center">
                      {loding === false ? <>
            {subCategory.map((data) => {
              const imageUrl =
                subcategoryImageMapping[data.contentsubcategoryname] ||
                defaultImageUrl;
              return (
                <Col md={6} lg={3}>
                  <NavHashLink
                    key={data.contentsubcategoryname}
                    to={`/collections/products/${data.contentsubcategoryname}/#products`}
                  >
                    <div className="card-container">
                      <div className="card-image-container mb-4">
                        <img src={imageUrl} alt={data.contentsubcategoryname} />
                      </div>
                      <div>
                        <h5 className="text-center">
                          {data.contentsubcategoryname}
                        </h5>
                      </div>
                    </div>
                  </NavHashLink>
                </Col>
              );
            })}</> : <div className="justify-content-center d-flex">
            <Circles
              type="TailSpin"
              color="orange"
              height={70}
              width={70}
              timeout={5000}
            />
          </div>}
          </Row>
          {/* <CollectionContainer justify="true" /> */}
        </div>
      </div>

      <div className="section-container">
        <Title
          classname="text-center mb-lg-5 mb-3"
          heading="Best Seller Honey 🔥"
        />

        <div className="container ">
          <iframe
            className="video-container"
            title="vimeo-player"
            src="https://player.vimeo.com/video/722834425?h=f3a1212ad5"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div className="section-container">
        <div className="container">
          <div className="section-sub-container text-center">
            <Title name="text-center mb-4" heading="Livasa Natural Products" />
            <p className="mb-5">
              Livasa products are processed for a rich taste and quality.
            </p>
          </div>
          <Row>
            {loding === false ? (
              <>
                {getHoneyProducts.length !== 0 ? (
                  <Slider {...settings}>
                    {getHoneyProducts.map((e) => {
                      return (
                        <Col
                          md={12}
                          lg={6}
                          sm={12}
                          xs={12}
                          className="px-3 justify-self-center"
                        >
                          <Products
                            src={e.productimageurl}
                            src1={e.qacertificateurl}
                            produtName={e.productfriendlyname}
                            discount={e.discountedprice}
                            productPrice={e.productcost}
                            origin={e.country}
                            url={e.infourl}
                            count={e.itemcount}
                            qr={e.productqrcodeurl}
                            likes={e.likes}
                            certificate={e.qacertificateurl}
                            model_id={e.productid}
                            likecount={e.likecount}
                            product={e}
                            buylink={e.buyurl}
                          />
                        </Col>
                      );
                    })}
                  </Slider>
                ) : (
                  <h3 className="title">Products Not Found</h3>
                )}
              </>
            ) : (
              <div className="justify-content-center d-flex">
                <Circles
                  type="TailSpin"
                  color="orange"
                  height={70}
                  width={70}
                  timeout={5000}
                />
              </div>
            )}
          </Row>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="section-container benefit-container">
        <div className="container">
          <div className="section-sub-container">
            <Title classname="text-center mb-4" heading="Benefits of Honey" />
            <p className=" text-center mb-5">
              Livasa Pure Honey is a natural antioxidant. Honey is often used to
              build immunity and acts as a pre-workout energizer. A spoonful of
              honey is a perfect remedy for:
            </p>
          </div>
        </div>
        <img src={Benefits} alt="Benefits" />
      </div>
      <div className="section-container mb-5">
        <Title classname="text-center mb-5" heading="Technology Partners" />
        <div className="container">
          <Row className="justify-content-center">
            <Col md={3} sm={6}>
              <a href="https://www.gbrfarming.com/" target="_blank" rel="noreferrer">
                {" "}
                <img src={GBRFarminglogo} className="d-block mx-auto gbr-partner" alt="Logo" />
              </a>
            </Col>
          </Row>
        </div>
      </div>

      {/* <CTA /> */}
      {/* Our Clients Section */}
      {/* <div className="section-container">
        <Title
          classname="text-center mb-5"
          heading="Our Happy B2B Clients"
        />
        <div className="container">
          <Row>
            {review.map((e) => {
              return (
                <Col md={12} lg={4}>
                  <ReviewContainer
                    src={e.image}
                    reviews={e.review}
                    description={e.description}
                    ratings={e.ratings}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      <div className="section-container">
        <Title
          classname="text-center mb-5"
          heading="Our Happy B2C Clients"
        />
        <div className="container">
          <Row>
            {review.map((e) => {
              return (
                <Col md={12} lg={4}>
                  <ReviewContainer
                    src={e.image}
                    reviews={e.review}
                    description={e.description}
                    ratings={e.ratings}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div> */}
    </div>
  );
}
